<script>
  // import {
  //    onMount,
  //    onDestroy
  // } from "svelte";
  import { onMount, onDestroy } from "svelte";

  const basetext = "Logged in. Redirecting";
  let dots = 0;

  $: text = basetext + ".".repeat(dots);

  let iv;
  onMount(() => {
    console.log("Mounted");
    iv = setInterval(() => {
      dots++;
      if (dots > 3) dots = 0;
    }, 500);
  });

  onDestroy(() => {
    console.log("on Destroy");
    clearInterval(iv);
  });
</script>

<style>
  .checkmark__circle {
    stroke-dasharray: 166;
    stroke-dashoffset: 166;
    stroke-width: 2;
    stroke-miterlimit: 10;
    stroke: #7ac142;
    fill: none;
    animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  }

  .checkmark {
    width: 56px;
    height: 56px;
    border-radius: 50%;
    display: block;
    stroke-width: 2;
    stroke: #fff;
    stroke-miterlimit: 10;
    margin: 10% auto;
    box-shadow: inset 0px 0px 0px #7ac142;
    animation: fill 0.4s ease-in-out 0.4s forwards,
      scale 0.3s ease-in-out 0.9s both;
  }

  .checkmark__check {
    transform-origin: 50% 50%;
    stroke-dasharray: 48;
    stroke-dashoffset: 48;
    animation: stroke 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  }

  @keyframes stroke {
    100% {
      stroke-dashoffset: 0;
    }
  }

  @keyframes scale {
    0%,
    100% {
      transform: none;
    }

    50% {
      transform: scale3d(1.1, 1.1, 1);
    }
  }

  @keyframes fill {
    100% {
      box-shadow: inset 0px 0px 0px 30px #7ac142;
    }
  }

  .scale {
    transform: scale(1.5);
  }
</style>

<div class="scale">
  <svg class="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
    <circle class="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
    <path
      class="checkmark__check"
      fill="none"
      d="M14.1 27.2l7.1 7.2 16.7-16.8" />
  </svg>
</div>
<!-- <div style="text-align: center;"> -->
<h3>{text}</h3>
<!-- </div> -->
