<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Error from "./Error.svelte";

  let password: string = "";
  export let username: string;

  const dispatch = createEventDispatcher();
</script>

<h3>Enter the password for {username}</h3>
<div class="floating group">
  <!-- svelte-ignore a11y-autofocus -->
  <input
    id="password"
    type="password"
    autocomplete="password"
    autofocus
    bind:value={password}
  />
  <span class="highlight" />
  <span class="bar" />
  <label for="password">Password</label>
  <Error />
</div>

<button
  class="btn btn-primary btn-wide"
  on:click={() => dispatch("password", password)}>Next</button
>
