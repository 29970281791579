<script lang="ts">
  // import { Tile } from "carbon-components-svelte";

  export let title: string;
  export let loading = false;
  export let hide = false;

  $: console.log({ loading });
</script>

<div class="wrapper">
  <div class="card-elevated container">
    <!-- <div class="container card"> -->
    <div class="card elv-8 title-container">
      <h1 style="margin:0">{title}</h1>
    </div>
    {#if loading}
      <div class="loader_container">
        <div class="loader_box">
          <div class="loader" />
        </div>
      </div>
    {/if}

    <div class="content-container" class:loading_container={loading}>
      {#if !(loading && hide)}
        <slot />
      {/if}
    </div>
    <!-- </div> -->
  </div>
</div>

<style>
  .wrapper {
    min-height: 100vh;
    width: 100vw;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    box-sizing: border-box;
  }

  .container {
    border-radius: 4px;
    position: relative;
    padding-top: 2.5rem;
    width: 25rem;

    min-height: calc(100px + 2.5rem);
    min-width: 100px;

    margin-top: 3rem;
  }

  .title-container {
    margin: -4.8rem auto 0 auto;
    max-width: 250px;
    background-color: var(--primary);
    color: white;
    border-radius: 4px;
    text-align: center;
    /* padding: 5px 20px; */
  }

  .title-container > h1 {
    font-size: 2rem;
    line-height: 1;
  }

  .content-container {
    padding: 2em;
    margin: 0 auto;
    max-width: 380px;
    overflow: hidden;
  }

  .loading_container {
    filter: blur(1px) opacity(50%);
  }

  .loader_container {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
  }
</style>
