import "@hibas123/theme/out/base.css";
import "./theme.css";
import { default as Theme } from "./Theme.svelte";

(() => {
   const elements = new WeakSet();

   function check() {
      document
         .querySelectorAll(".floating>input")
         .forEach((e: HTMLInputElement) => {
            if (elements.has(e)) return;
            elements.add(e);

            function checkState() {
               console.log("Check State");
               if (e.value !== "") {
                  if (e.classList.contains("used")) return;
                  e.classList.add("used");
               } else {
                  if (e.classList.contains("used")) e.classList.remove("used");
               }
            }

            e.addEventListener("change", () => checkState());
            checkState();
         });
   }

   const observer = new MutationObserver((mutations) => {
      check();
   });

   // Start observing the target node for configured mutations
   observer.observe(window.document, {
      childList: true,
      subtree: true,
   });
   check();
})();

export default Theme;
