<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import loginState from "./state";
  import Icon from "./icons/Icon.svelte";
  import { TFAType } from "@hibas123/openauth-internalapi";
  import { onMount } from "svelte";
  import Totp from "./TF/TOTP.svelte";
  import Error from "./Error.svelte";
  import WebAuthn from "./TF/WebAuthn.svelte";

  let { state } = loginState;

  const dispatch = createEventDispatcher();

  let selected = undefined;

  $: {
    if ($state.requireTwoFactor?.length == 1) {
      selected = $state.requireTwoFactor[0];
    }
  }

  const typeIconMap = {
    [TFAType.TOTP]: "Authenticator",
    [TFAType.BACKUP_CODE]: "BackupCode",
    [TFAType.WEBAUTHN]: "SecurityKey",
    [TFAType.APP_ALLOW]: "AppPush",
  };
</script>

{#if !selected}
  <h3>Choose your 2FA method</h3>
  <ul>
    {#each $state.requireTwoFactor ?? [] as method}
      <!-- svelte-ignore a11y-click-events-have-key-events -->
      <li on:click={() => (selected = method)}>
        <div class="icon">
          <Icon icon_name={typeIconMap[method.tfatype]} />
        </div>

        <div class="name">{method.name}</div>
      </li>
    {/each}

    <Error />
  </ul>
{:else}
  {#if selected.tfatype == TFAType.TOTP}
    <Totp id={selected.id} name={selected.name} />
  {:else if selected.tfatype == TFAType.BACKUP_CODE}
    backup
  {:else if selected.tfatype == TFAType.WEBAUTHN}
    <WebAuthn id={selected.id} />
  {:else if selected.tfatype == TFAType.APP_ALLOW}
    appallow
  {:else}
    <p>Unknown 2FA type</p>
  {/if}

  <p>
    <a
      class="to-list"
      href="# "
      on:click={(evt) => {
        evt.preventDefault();
        loginState.setError(undefined);
        selected = undefined;
      }}
    >
      Choose another Method
    </a>
  </p>
{/if}

<style>
  ul {
    list-style: none;
    padding-inline-start: 0;
    margin-bottom: 0;
  }

  li {
    border-top: 1px grey solid;
    padding: 1em;
    cursor: pointer;
    display: flex;
  }

  li:hover {
    background-color: #e2e2e2;
  }

  li:first-child {
    border-top: none !important;
  }

  .icon {
    height: 1.5rem;
    width: 1.5rem;
  }

  .name {
    margin-left: 1rem;
    line-height: 1.5rem;
    font-size: 20px;
    flex-grow: 1;
  }

  .to-list {
    color: var(--primary);
    text-decoration: none;
    margin-right: 1rem;
  }
</style>
