var b;
if (!(b = t)) {
   var w = Math,
      y = {},
      B = (y.p = {}),
      aa = function () {},
      C = (B.A = {
         extend: function (o) {
            aa.prototype = this;
            var _ = new aa();
            return o && _.u(o), (_.z = this), _;
         },
         create: function () {
            var o = this.extend();
            return o.h.apply(o, arguments), o;
         },
         h: function () {},
         u: function (o) {
            for (var _ in o) o.hasOwnProperty(_) && (this[_] = o[_]);
            o.hasOwnProperty("toString") && (this.toString = o.toString);
         },
         e: function () {
            return this.z.extend(this);
         },
      }),
      D = (B.i = C.extend({
         h: function (o, _) {
            (o = this.d = o || []), (this.c = void 0 == _ ? 4 * o.length : _);
         },
         toString: function (o) {
            return (o || ba).stringify(this);
         },
         concat: function (o) {
            var _ = this.d,
               Da = o.d,
               Ea = this.c,
               o = o.c;
            if ((this.t(), Ea % 4))
               for (var Fa = 0; Fa < o; Fa++)
                  _[(Ea + Fa) >>> 2] |=
                     (255 & (Da[Fa >>> 2] >>> (24 - 8 * (Fa % 4)))) <<
                     (24 - 8 * ((Ea + Fa) % 4));
            else if (65535 < Da.length)
               for (Fa = 0; Fa < o; Fa += 4) _[(Ea + Fa) >>> 2] = Da[Fa >>> 2];
            else _.push.apply(_, Da);
            return (this.c += o), this;
         },
         t: function () {
            var o = this.d,
               _ = this.c;
            (o[_ >>> 2] &= 4294967295 << (32 - 8 * (_ % 4))),
               (o.length = w.ceil(_ / 4));
         },
         e: function () {
            var o = C.e.call(this);
            return (o.d = this.d.slice(0)), o;
         },
         random: function (o) {
            for (var _ = [], Da = 0; Da < o; Da += 4)
               _.push(0 | (4294967296 * w.random()));
            return D.create(_, o);
         },
      })),
      ca = (y.O = {}),
      ba = (ca.K = {
         stringify: function (o) {
            for (var Fa, _ = o.d, o = o.c, Da = [], Ea = 0; Ea < o; Ea++)
               (Fa = 255 & (_[Ea >>> 2] >>> (24 - 8 * (Ea % 4)))),
                  Da.push((Fa >>> 4).toString(16)),
                  Da.push((15 & Fa).toString(16));
            return Da.join("");
         },
         parse: function (o) {
            for (var _ = o.length, Da = [], Ea = 0; Ea < _; Ea += 2)
               Da[Ea >>> 3] |=
                  parseInt(o.substr(Ea, 2), 16) << (24 - 4 * (Ea % 8));
            return D.create(Da, _ / 2);
         },
      }),
      da = (ca.M = {
         stringify: function (o) {
            for (var _ = o.d, o = o.c, Da = [], Ea = 0; Ea < o; Ea++)
               Da.push(
                  String.fromCharCode(
                     255 & (_[Ea >>> 2] >>> (24 - 8 * (Ea % 4)))
                  )
               );
            return Da.join("");
         },
         parse: function (o) {
            for (var _ = o.length, Da = [], Ea = 0; Ea < _; Ea++)
               Da[Ea >>> 2] |= (255 & o.charCodeAt(Ea)) << (24 - 8 * (Ea % 4));
            return D.create(Da, _);
         },
      }),
      ea = (ca.N = {
         stringify: function (o) {
            try {
               return decodeURIComponent(escape(da.stringify(o)));
            } catch (_) {
               throw Error("Malformed UTF-8 data");
            }
         },
         parse: function (o) {
            return da.parse(unescape(encodeURIComponent(o)));
         },
      }),
      ia = (B.I = C.extend({
         reset: function () {
            (this.g = D.create()), (this.j = 0);
         },
         l: function (o) {
            "string" == typeof o && (o = ea.parse(o)),
               this.g.concat(o),
               (this.j += o.c);
         },
         m: function (o) {
            var _ = this.g,
               Da = _.d,
               Ea = _.c,
               Fa = this.n,
               Ga = Ea / (4 * Fa),
               Ga = o ? w.ceil(Ga) : w.max((0 | Ga) - this.r, 0),
               o = Ga * Fa,
               Ea = w.min(4 * o, Ea);
            if (o) {
               for (var Ha = 0; Ha < o; Ha += Fa) this.H(Da, Ha);
               (Ha = Da.splice(0, o)), (_.c -= Ea);
            }
            return D.create(Ha, Ea);
         },
         e: function () {
            var o = C.e.call(this);
            return (o.g = this.g.e()), o;
         },
         r: 0,
      }));
   B.B = ia.extend({
      h: function () {
         this.reset();
      },
      reset: function () {
         ia.reset.call(this), this.q();
      },
      update: function (o) {
         return this.l(o), this.m(), this;
      },
      o: function (o) {
         return o && this.l(o), this.G(), this.f;
      },
      e: function () {
         var o = ia.e.call(this);
         return (o.f = this.f.e()), o;
      },
      n: 16,
      D: function (o) {
         return function (_, Da) {
            return o.create(Da).o(_);
         };
      },
      F: function (o) {
         return function (_, Da) {
            return ja.J.create(o, Da).o(_);
         };
      },
   });
   var ja = (y.s = {});
   b = y;
}
var t = b,
   K = t,
   ka = K.p,
   la = ka.A,
   va = ka.i,
   K = (K.w = {});
(K.C = la.extend({
   h: function (o, _) {
      (this.a = o), (this.b = _);
   },
})),
   (K.i = la.extend({
      h: function (o, _) {
         (o = this.d = o || []), (this.c = void 0 == _ ? 8 * o.length : _);
      },
      v: function () {
         for (var Fa, o = this.d, _ = o.length, Da = [], Ea = 0; Ea < _; Ea++)
            (Fa = o[Ea]), Da.push(Fa.a), Da.push(Fa.b);
         return va.create(Da, this.c);
      },
      e: function () {
         for (
            var o = la.e.call(this),
               _ = (o.d = this.d.slice(0)),
               Da = _.length,
               Ea = 0;
            Ea < Da;
            Ea++
         )
            _[Ea] = _[Ea].e();
         return o;
      },
   }));
function L() {
   return wa.create.apply(wa, arguments);
}
for (
   var xa = t.p.B,
      M = t.w,
      wa = M.C,
      ya = M.i,
      M = t.s,
      za = [
         L(1116352408, 3609767458),
         L(1899447441, 602891725),
         L(3049323471, 3964484399),
         L(3921009573, 2173295548),
         L(961987163, 4081628472),
         L(1508970993, 3053834265),
         L(2453635748, 2937671579),
         L(2870763221, 3664609560),
         L(3624381080, 2734883394),
         L(310598401, 1164996542),
         L(607225278, 1323610764),
         L(1426881987, 3590304994),
         L(1925078388, 4068182383),
         L(2162078206, 991336113),
         L(2614888103, 633803317),
         L(3248222580, 3479774868),
         L(3835390401, 2666613458),
         L(4022224774, 944711139),
         L(264347078, 2341262773),
         L(604807628, 2007800933),
         L(770255983, 1495990901),
         L(1249150122, 1856431235),
         L(1555081692, 3175218132),
         L(1996064986, 2198950837),
         L(2554220882, 3999719339),
         L(2821834349, 766784016),
         L(2952996808, 2566594879),
         L(3210313671, 3203337956),
         L(3336571891, 1034457026),
         L(3584528711, 2466948901),
         L(113926993, 3758326383),
         L(338241895, 168717936),
         L(666307205, 1188179964),
         L(773529912, 1546045734),
         L(1294757372, 1522805485),
         L(1396182291, 2643833823),
         L(1695183700, 2343527390),
         L(1986661051, 1014477480),
         L(2177026350, 1206759142),
         L(2456956037, 344077627),
         L(2730485921, 1290863460),
         L(2820302411, 3158454273),
         L(3259730800, 3505952657),
         L(3345764771, 106217008),
         L(3516065817, 3606008344),
         L(3600352804, 1432725776),
         L(4094571909, 1467031594),
         L(275423344, 851169720),
         L(430227734, 3100823752),
         L(506948616, 1363258195),
         L(659060556, 3750685593),
         L(883997877, 3785050280),
         L(958139571, 3318307427),
         L(1322822218, 3812723403),
         L(1537002063, 2003034995),
         L(1747873779, 3602036899),
         L(1955562222, 1575990012),
         L(2024104815, 1125592928),
         L(2227730452, 2716904306),
         L(2361852424, 442776044),
         L(2428436474, 593698344),
         L(2756734187, 3733110249),
         L(3204031479, 2999351573),
         L(3329325298, 3815920427),
         L(3391569614, 3928383900),
         L(3515267271, 566280711),
         L(3940187606, 3454069534),
         L(4118630271, 4000239992),
         L(116418474, 1914138554),
         L(174292421, 2731055270),
         L(289380356, 3203993006),
         L(460393269, 320620315),
         L(685471733, 587496836),
         L(852142971, 1086792851),
         L(1017036298, 365543100),
         L(1126000580, 2618297676),
         L(1288033470, 3409855158),
         L(1501505948, 4234509866),
         L(1607167915, 987167468),
         L(1816402316, 1246189591),
      ],
      $ = [],
      Aa = 0;
   80 > Aa;
   Aa++
)
   $[Aa] = L();
(M = M.k = xa.extend({
   q: function () {
      this.f = ya.create([
         L(1779033703, 4089235720),
         L(3144134277, 2227873595),
         L(1013904242, 4271175723),
         L(2773480762, 1595750129),
         L(1359893119, 2917565137),
         L(2600822924, 725511199),
         L(528734635, 4215389547),
         L(1541459225, 327033209),
      ]);
   },
   H: function (o, _) {
      for (
         var qb,
            Da = this.f.d,
            Ea = Da[0],
            Fa = Da[1],
            Ga = Da[2],
            Ha = Da[3],
            Ia = Da[4],
            Ja = Da[5],
            Ka = Da[6],
            Da = Da[7],
            La = Ea.a,
            Ma = Ea.b,
            Na = Fa.a,
            Oa = Fa.b,
            Pa = Ga.a,
            Qa = Ga.b,
            Ra = Ha.a,
            Sa = Ha.b,
            Ta = Ia.a,
            Ua = Ia.b,
            Va = Ja.a,
            Wa = Ja.b,
            Xa = Ka.a,
            Ya = Ka.b,
            Za = Da.a,
            $a = Da.b,
            _a = La,
            ab = Ma,
            bb = Na,
            cb = Oa,
            db = Pa,
            eb = Qa,
            fb = Ra,
            gb = Sa,
            hb = Ta,
            ib = Ua,
            jb = Va,
            kb = Wa,
            lb = Xa,
            mb = Ya,
            nb = Za,
            ob = $a,
            pb = 0;
         80 > pb;
         pb++
      ) {
         if (((qb = $[pb]), 16 > pb))
            var rb = (qb.a = 0 | o[_ + 2 * pb]),
               sb = (qb.b = 0 | o[_ + 2 * pb + 1]);
         else {
            var rb = $[pb - 15],
               sb = rb.a,
               tb = rb.b,
               rb =
                  ((tb << 31) | (sb >>> 1)) ^
                  ((tb << 24) | (sb >>> 8)) ^
                  (sb >>> 7),
               tb =
                  ((sb << 31) | (tb >>> 1)) ^
                  ((sb << 24) | (tb >>> 8)) ^
                  ((sb << 25) | (tb >>> 7)),
               ub = $[pb - 2],
               sb = ub.a,
               vb = ub.b,
               ub =
                  ((vb << 13) | (sb >>> 19)) ^
                  ((sb << 3) | (vb >>> 29)) ^
                  (sb >>> 6),
               vb =
                  ((sb << 13) | (vb >>> 19)) ^
                  ((vb << 3) | (sb >>> 29)) ^
                  ((sb << 26) | (vb >>> 6)),
               sb = $[pb - 7],
               wb = sb.a,
               xb = $[pb - 16],
               yb = xb.a,
               xb = xb.b,
               sb = tb + sb.b,
               rb = rb + wb + (sb >>> 0 < tb >>> 0 ? 1 : 0),
               sb = sb + vb,
               rb = rb + ub + (sb >>> 0 < vb >>> 0 ? 1 : 0),
               sb = sb + xb,
               rb = rb + yb + (sb >>> 0 < xb >>> 0 ? 1 : 0);
            (qb.a = rb), (qb.b = sb);
         }
         var wb = (hb & jb) ^ (~hb & lb),
            xb = (ib & kb) ^ (~ib & mb),
            qb = (_a & bb) ^ (_a & db) ^ (bb & db),
            tb =
               ((ab << 4) | (_a >>> 28)) ^
               ((_a << 30) | (ab >>> 2)) ^
               ((_a << 25) | (ab >>> 7)),
            ub =
               ((_a << 4) | (ab >>> 28)) ^
               ((ab << 30) | (_a >>> 2)) ^
               ((ab << 25) | (_a >>> 7)),
            vb = za[pb],
            Ab = vb.a,
            Bb = vb.b,
            vb =
               ob +
               (((hb << 18) | (ib >>> 14)) ^
                  ((hb << 14) | (ib >>> 18)) ^
                  ((ib << 23) | (hb >>> 9))),
            yb =
               nb +
               (((ib << 18) | (hb >>> 14)) ^
                  ((ib << 14) | (hb >>> 18)) ^
                  ((hb << 23) | (ib >>> 9))) +
               (vb >>> 0 < ob >>> 0 ? 1 : 0),
            vb = vb + xb,
            yb = yb + wb + (vb >>> 0 < xb >>> 0 ? 1 : 0),
            vb = vb + Bb,
            yb = yb + Ab + (vb >>> 0 < Bb >>> 0 ? 1 : 0),
            vb = vb + sb,
            yb = yb + rb + (vb >>> 0 < sb >>> 0 ? 1 : 0),
            sb = ub + ((ab & cb) ^ (ab & eb) ^ (cb & eb)),
            qb = tb + qb + (sb >>> 0 < ub >>> 0 ? 1 : 0),
            nb = lb,
            ob = mb,
            lb = jb,
            mb = kb,
            jb = hb,
            kb = ib,
            ib = 0 | (gb + vb),
            hb = 0 | (fb + yb + (ib >>> 0 < gb >>> 0 ? 1 : 0)),
            fb = db,
            gb = eb,
            db = bb,
            eb = cb,
            bb = _a,
            cb = ab,
            ab = 0 | (vb + sb),
            _a = 0 | (yb + qb + (ab >>> 0 < vb >>> 0 ? 1 : 0));
      }
      (Ma = Ea.b = 0 | (Ma + ab)),
         (Ea.a = 0 | (La + _a + (Ma >>> 0 < ab >>> 0 ? 1 : 0))),
         (Oa = Fa.b = 0 | (Oa + cb)),
         (Fa.a = 0 | (Na + bb + (Oa >>> 0 < cb >>> 0 ? 1 : 0))),
         (Qa = Ga.b = 0 | (Qa + eb)),
         (Ga.a = 0 | (Pa + db + (Qa >>> 0 < eb >>> 0 ? 1 : 0))),
         (Sa = Ha.b = 0 | (Sa + gb)),
         (Ha.a = 0 | (Ra + fb + (Sa >>> 0 < gb >>> 0 ? 1 : 0))),
         (Ua = Ia.b = 0 | (Ua + ib)),
         (Ia.a = 0 | (Ta + hb + (Ua >>> 0 < ib >>> 0 ? 1 : 0))),
         (Wa = Ja.b = 0 | (Wa + kb)),
         (Ja.a = 0 | (Va + jb + (Wa >>> 0 < kb >>> 0 ? 1 : 0))),
         (Ya = Ka.b = 0 | (Ya + mb)),
         (Ka.a = 0 | (Xa + lb + (Ya >>> 0 < mb >>> 0 ? 1 : 0))),
         ($a = Da.b = 0 | ($a + ob)),
         (Da.a = 0 | (Za + nb + ($a >>> 0 < ob >>> 0 ? 1 : 0)));
   },
   G: function () {
      var o = this.g,
         _ = o.d,
         Da = 8 * this.j,
         Ea = 8 * o.c;
      (_[Ea >>> 5] |= 128 << (24 - (Ea % 32))),
         (_[(((Ea + 128) >>> 10) << 5) + 31] = Da),
         (o.c = 4 * _.length),
         this.m(),
         (this.f = this.f.v());
   },
   n: 32,
})),
   (t.k = xa.D(M)),
   (t.L = xa.F(M));
export default function sha512(o) {
   return t.k(o) + "";
}
