<script lang="ts">
  import {} from "flowbite-svelte";

  import { LoginState } from "@hibas123/openauth-internalapi";
  import Theme from "../../components/theme";
  import loginState from "./state";
  import HoveringContentBox from "../../components/HoveringContentBox.svelte";
  import { onMount } from "svelte";
  import Username from "./Username.svelte";
  import Password from "./Password.svelte";
  import Success from "./Success.svelte";
  import TwoFactor from "./TwoFactor.svelte";

  const { state } = loginState;
</script>

<Theme>
  <HoveringContentBox title="Login" loading={$state.loading}>
    <form action="JavaScript:void(0)">
      {#if $state.success}
        <Success />
      {:else if !$state.username}
        <Username on:username={(evt) => loginState.setUsername(evt.detail)} />
      {:else if !$state.password}
        <Password
          username={$state.username}
          on:password={(evt) => loginState.setPassword(evt.detail)}
        />
      {:else if $state.requireTwoFactor.length > 0}
        <TwoFactor />
      {/if}
    </form>
  </HoveringContentBox>
</Theme>
