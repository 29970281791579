<script>
  import Cleave from "cleave.js";
  import { onMount } from "svelte";
  import Error from "../Error.svelte";

  // export let label;
  export let value;
  export let length = 6;

  let input;
  onMount(() => {
    const cleaveCustom = new Cleave(input, {
      blocks: [length / 2, length / 2],
      delimiter: " ",
      numericOnly: true,
    });
  });
</script>

<div class="floating group">
  <input id="code-input" bind:this={input} autofocus bind:value />
  <span class="highlight" />
  <span class="bar" />
  <label for="code-input">Code</label>

  <Error />
</div>
