<script lang="ts">
  import { createEventDispatcher } from "svelte";
  import Error from "./Error.svelte";

  let username: string = "";

  const dispatch = createEventDispatcher();
</script>

<h3>Enter your Username or your E-Mail Address</h3>
<div class="floating group">
  <!-- svelte-ignore a11y-autofocus -->
  <input
    id="username"
    type="text"
    autocomplete="username"
    autofocus
    bind:value={username}
  />
  <span class="highlight" />
  <span class="bar" />
  <label for="username">Username or E-Mail</label>
  <Error />
</div>

<button
  class="btn btn-primary btn-wide"
  on:click={() => dispatch("username", username)}>Next</button
>
