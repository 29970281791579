import { Client } from "@hibas123/openauth-internalapi";
import request, { RequestError } from "./request";

const provider = new Client.ServiceProvider((data) => {
   fetch("/api/jrpc", {
      method: "POST",
      credentials: "same-origin",
      headers: {
         "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
   }).then(res => {
      if (res.ok) return res.json();
      else throw new Error(res.statusText);
   }).then(res => {
      provider.onPacket(res);
   }).catch(err => {
      provider.onPacket({
         jsonrpc: "2.0",
         method: data.method,
         id: data.id,
         error: {
            code: -32603,
            message: err.message,
         },
      })
   })
});

const InternalAPI = {
   Account: new Client.AccountService(provider),
   Security: new Client.SecurityService(provider),
   TwoFactor: new Client.TFAService(provider),
   Login: new Client.LoginService(provider),
}

export default InternalAPI;

(window as any).InternalAPI = InternalAPI;
