<script lang="ts">
  import Error from "../Error.svelte";
  import loginState from "../state";
  import CodeInput from "./CodeInput.svelte";

  export let id: string;
  export let name: string;

  let code: string = "";

  function send() {
    loginState.useTOTP(id, code.replace(/\s+/g, ""));
  }
</script>

<h3>TOTP {name}</h3>
<CodeInput bind:value={code} length={6} />

<div class="actions">
  <button class="btn btn-primary btn-wide" on:click={send}> Send </button>
</div>
