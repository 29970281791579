<script lang="ts">
  // import { onMount, afterUpdate, setContext } from "svelte";
  // import { writable, derived } from "svelte/store";

  // type Theme = "white" | "g10" | "g90" | "g100";

  // export let persist: boolean = false;
  // export let persistKey: string = "theme";
  export let dark = false;
</script>


<div class={dark ? 'dark-theme' : 'light-theme'}>
  <slot />
</div>
