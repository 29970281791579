<script lang="ts">
  import loginState from "./state";

  let { state } = loginState;
</script>

{#if $state.error}
  <div class="error">{$state.error}</div>
{/if}

<style>
  .error {
    color: var(--error);
    padding: 4px;
  }
</style>
