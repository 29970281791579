<script lang="ts">
  import { onMount } from "svelte";
  import Error from "../Error.svelte";
  import loginState from "../state";
  import { startAuthentication } from "@simplewebauthn/browser";

  export let id: string;

  async function doAuth() {
    let challenge = await loginState.getWebAuthnChallenge(id);
    try {
      loginState.setLoading(true);
      let result = await startAuthentication(JSON.parse(challenge));
      await loginState.useWebAuthn(id, result);
    } catch (e) {
      loginState.setError(e.message);
      return;
    } finally {
      loginState.setLoading(false);
    }
  }

  onMount(() => {
    doAuth();
  });
</script>

<Error />
